import { Brand } from './typings'

const walmart: Brand = {
  clientInfo: {
    displayName: 'Walmart',
    accountNumberLengths: [16],
    footerDisplay: 'Walmart.com',
    footerLink: 'https://www.walmart.com/',
    applicationUrl: '',
    dsecApigeeClientId: {
      development: 'zlShwYtLpG9weJwvZq4P1amXXkjuFFXH',
      production: '3HA3RPWpAFNcnvFAHTIqMM1zfj7tu9lx'
    },
    persistApigeeClientId: { development: '', production: '' },
    setpayApigeeClientId: {
      development: '',
      production: ''
    },
    metaTagCardTitle: {
      en: 'Credit Card',
      es: 'Tarjeta de Crédito'
    },
    metaTagCardDescription: {
      en: `Log into your Walmart credit card account online to pay your bills, check your FICO score, sign up for paperless billing, and manage your account preferences`,
      es: `Ingrese a su cuenta de la Tarjeta de Crédito Walmart en línea para pagar sus facturas, revisar su puntuación FICO, inscribirse en facturas digitales y manejar su cuenta.`
    },
    languages: ['en'],
    vanityUrl: 'walmart.com',
    hostname: 'walmart.syf.com',
    linkingRedirect: '',
    ivaKey: '',
    hasMss: true,
    hasCpl: false,
    promoUrl: '',
    hasCplOldErrorCode: false,
    isMultiAccount: false,
    footerContent: {
      en: 'Your Walmart Credit Card or Walmart Mastercard® is issued by Synchrony Bank. The Synchrony Bank Privacy Policy governs the use of the Walmart Card or Walmart Mastercard®. The use of this site is governed by the use of the Synchrony Bank Internet Privacy Policy, which is different from the privacy policy of Walmart®.',
      es: ''
    },
    phoneNumbers: {
      loan: '',
      plcc: '1-855-893-5848',
      dualcard: '1-855-872-7950',
      fraud: '1-855-872-7950',
      customerService: '1-855-893-5848'
    },
    iOSAppId: '',
    androidApp: {
      id: '',
      author: '',
      title: '',
      image: ''
    },
    crossBorder: false,
    cplHeroBannerDisplayName: '',
    termsOfService: {
      en: '/essimages/cs/groups/ess_webasset/generic/@walmart/en/html/ess010352.html',
      es: ''
    },
    onlinePrivacy: {
      en: '/essimages/cs/groups/ess_webasset/generic/@walmart/en/html/ess010349.html',
      es: ''
    },
    privacyPolicy: {
      en: '/essimages/cs/groups/ess_webasset/generic/@walmart/en/html/ess010350.html',
      es: ''
    },
    websiteUsage: {
      en: '/essimages/cs/groups/ess_webasset/generic/@walmart/en/html/ess010351.html',
      es: ''
    },
    voice: {
      cardName: '',
      google: {
        termsAndConditionsLink: ''
      },
      alexa: {
        termsAndConditionsLink: ''
      }
    }
  },
  palette: {
    button: {
      primary: {
        text: '#333333',
        hover: '#FFCB3F'
      },
      secondary: {
        hover: '#cfdae5'
      },
      background: '#FFBA00'
    },
    field: {
      icon: '#087F19',
      focus: '#4d90fe',
      label: ''
    },
    progressStepBar: {
      fill: '#0077CA',
      label: '#0077CA'
    },
    progressBar: {
      complete: '#0077CA',
      error: '#c63527',
      inProgress: '#0072ad',
      background: '#e9eaeb'
    },
    checkbox: {
      background: '#387c2b',
      font: '#FFFFFF'
    },
    switch: { background: '' },
    heroBackground: '#052244',
    bodyBackground: '#FFFFFF',
    iconBackground: '#F2F2F2',
    headerBackground: '#FFFFFF',
    modalBackground: '#FFFFFF',
    formBackground: '#F2F2F2',
    headerDivider: '#0077CA',
    footerDivider: '#FFBA00',
    contrastingText: '#333333',
    bodyText: '#333333',
    languageSelector: '#0077CA',
    focus: '#4d90fe',
    link: '#087F19',
    footer: {
      background: '#0077CA',
      text: '#FFFFFF',
      link: '#FFFFFF'
    }
  },
  images: {
    logo: {
      en: '/assets/synchrony/logo.svg',
      es: '/assets/synchrony/logo.svg'
    },
    hero: '',
    heroBgDesktop: '',
    heroBgMobile: '',
    mainPromo: {
      en: '/assets/walmart/promo.svg',
      es: '/assets/walmart/promo.svg'
    },
    voiceCplLogo: ''
  }
}

export default walmart
